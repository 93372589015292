const GalleryPage = ({ data }) => {
  const hero = data.galleryYaml.galleryHero

  return (
    <Base>
      <PageMetadata page={data.metadata.siteMetadata.galleryPage} />

      <main id="main-content" tabIndex="-1" className="sans-serif">
        <Hero
          heading={hero.heading}
          image={hero.image.childImageSharp.fluid}
          alt={hero.alt}
          objPosition={hero.objPosition}
          quote={hero.quote}
          source={hero.source}
          link={hero.link}
        />

        <Gallery
          portrait={data.allImagesPortraitYaml.edges}
          onstage={data.allImagesOnstageYaml.edges}
          offstage={data.allImagesOffstageYaml.edges}
        />
      </main>
    </Base>
  )
}

/*
 *
 * Queries
 * 
 */

export const query = graphql`
  query GalleryQuery {
    metadata: site {
      siteMetadata {
        galleryPage {
          description
          title
          url
        }
      }
    }
    galleryYaml {
      galleryHero {
        heading
        image {
          childImageSharp {
            fluid(maxWidth: 1120) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alt
        objPosition
        quote
        source
        link
      }
    }
    allImagesPortraitYaml {
      edges {
        node {
          image {
            childImageSharp {
              thumbnail: fluid(maxWidth: 1760) {
                ...GatsbyImageSharpFluid_withWebp
              }
              lightbox: fixed(width: 1500) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          alt
          objPosition
          caption
          category
        }
      }
    }
    allImagesOnstageYaml {
      edges {
        node {
          image {
            childImageSharp {
              thumbnail: fluid(maxWidth: 1760) {
                ...GatsbyImageSharpFluid_withWebp
              }
              lightbox: fixed(width: 1500) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          alt
          objPosition
          caption
          category
        }
      }
    }
    allImagesOffstageYaml {
      edges {
        node {
          image {
            childImageSharp {
              thumbnail: fluid(maxWidth: 1760) {
                ...GatsbyImageSharpFluid_withWebp
              }
              lightbox: fixed(width: 1500) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          alt
          objPosition
          caption
          category
        }
      }
    }
  }
`

/*
 *
 * Imports & Exports
 * 
 */

import React from 'react'
import { graphql } from 'gatsby'

import Gallery from '../sections/GalleryImages'

import Base from '../components/Base'
import Hero from '../components/Hero'
import PageMetadata from '../components/PageMetadata'

export default GalleryPage
