const Gallery = ({ portrait, onstage, offstage }) => {
  let limit = 3

  // Wait for the document to exist
  if (typeof window !== `undefined`) {
    // Create a test element to check for grid support
    const testEl = document.createElement(`div`)
    testEl.style.display = `grid`

    // If grid is supported, show more items initially on large screens
    if (testEl.style.display === `grid`) {
      if (window.matchMedia(`(min-width: 62em)`).matches) {
        limit = 12
      } else if (window.matchMedia(`(min-width: 48em)`).matches) {
        limit = 9
      } else if (window.matchMedia(`(min-width: 36em)`).matches) {
        limit = 6
      }
    }
  }

  return (
    <section className="bg-near-white pv5">
      <div className="container pv4 sm:mv3">
        <h2 className="section-heading mb5">Photos</h2>

        <FilterAndLimit
          portrait={portrait} // portrait images
          onstage={onstage} // gallery images
          offstage={offstage} // offstage images
          defaultCategory="portrait"
          limit={limit}
          increment={limit}
          renderFilters={handleFilter => <Filters handleFilter={handleFilter} />}
          renderItems={(items, visibleItems) => (
            <ImageGalleryAndLightbox
              galleryImages={visibleItems}
              lightboxImages={items}
              renderGallery={(galleryImages, handleImageClick) => (
                <Thumbnails
                  items={galleryImages}
                  handleImageClick={handleImageClick}
                />
              )}
            />
          )}
          renderSeeMore={handleSeeMore => <SeeMore handleSeeMore={handleSeeMore} />}
        />
      </div>
    </section>
  )
}

/*
 *
 * Filters
 *
 */

const Filters = ({ handleFilter }) => (
  <fieldset className="mb3">
    <legend className="sr-only">Pick a category</legend>

    <FilterBtnRadio
      group="gallery"
      category="portrait"
      label="Portrait"
      defaultChecked={true}
      handleFilter={handleFilter}
      className="link mr3 mb3"
    />

    <FilterBtnRadio
      group="gallery"
      category="onstage"
      label="Onstage"
      handleFilter={handleFilter}
      className="link mr3 mb3"
    />

    <FilterBtnRadio
      group="gallery"
      category="offstage"
      label="Offstage"
      handleFilter={handleFilter}
      className="link mb3"
    />
  </fieldset>
)

/*
 *
 * Thumbnails
 *
 */

class Thumbnails extends React.Component {
  state = { gsapReady: false }

  componentDidMount = () =>
    loadjs.ready(`gsap`, () => this.setState({ gsapReady: true }))

  render() {
    const { items, handleImageClick } = this.props
    const { gsapReady } = this.state

    return (
      <ul className="gallery-grid">
        {/* Show real images once GSAP is ready (to avoid an initial flash of content) */}
        {gsapReady ? (
          <TransitionGroup component={null}>
            {items.map((item, i) => (
              <Mount
                key={item.node.image.childImageSharp.thumbnail.src}
                animateExit={false}
                animateSpace={false}
                enterTimeout={0.75}
              >
                <Thumbnail
                  item={item.node}
                  lightboxIndex={i}
                  handleImageClick={handleImageClick}
                />
              </Mount>
            ))}
          </TransitionGroup>
        ) : (
          <Fragment>
            {/* Until GSAP is ready, show a hidden version of the images (to avoid a space jump) */}
            {items.map((item, i) => (
              <Thumbnail
                key={item.node.image.childImageSharp.thumbnail.src}
                item={item.node}
                lightboxIndex={i}
                handleImageClick={handleImageClick}
                className="o-0"
              />
            ))}
          </Fragment>
        )}
      </ul>
    )
  }
}

/*
 *
 * Thumbnail
 *
 */

const Thumbnail = ({ item, lightboxIndex, handleImageClick, className = `` }) => (
  <li className={`group relative mt3 ${className}`}>
    <Img
      fluid={item.image.childImageSharp.thumbnail}
      alt={item.alt}
      objPosition={item.objPosition}
      className="aspect-ratio aspect-ratio--1x1"
    />

    {/* Overlay + Lightbox trigger */}
    <button
      onClick={handleImageClick}
      value={lightboxIndex}
      className="flex justify-center items-center absolute fill bg-transparent w-100 group-hover:bg-black-80 f4 ttu tracked transparent group-hover:white animate"
    >
      View image
    </button>
  </li>
)

/*
 *
 * See More Button
 *
 */

const SeeMore = ({ handleSeeMore }) => (
  <button onClick={handleSeeMore} className="link mt5">
    See More Photos
  </button>
)

/*
 *
 * Imports & Exports
 * 
 */

import React, { Fragment } from 'react'
import loadjs from 'loadjs'
import TransitionGroup from 'react-transition-group/TransitionGroup'

import FilterAndLimit from '../components/FilterAndLimit'
import FilterBtnRadio from '../components/FilterBtnRadio'
import ImageGalleryAndLightbox from '../components/ImageGalleryAndLightbox'
import Img from '../components/Img'
import Mount from '../components/Mount'

export default Gallery
